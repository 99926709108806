.customModalOne_body {
    position: relative;
}

.close {
    background: var(--theme-color);
    border: none;
    outline: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
}



.customModalOne_body .header .title {
    font-family: var(--open-sans-font);
    font-weight: 600;
}

.customModalOne_body .header .description {
    font-family: var(--open-sans-font);
    font-weight: 550;
    font-style: italic;
}

.customModalOne_body .header .description span {
    color: var(--red-color-300);
}

.customModalOne_body .cardGray {
    background: var(--bg-color-3);
    padding-top: 10px;
    padding-bottom: 10px;
}

.textarea {
    min-height: 150px;
    width: 100%;
    border: 1px solid var(--bg-color-13);
    border-radius: 10px;
    box-shadow: 0px 5px 5px 0px #8080804a;
    padding: 10px;
}

.formButton {
    width: 75%;
    height: 50px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-color-2);
    color: var(--theme-color);
    font-weight: 700;
    border-radius: 10px;
}

.formButton:hover {
    opacity: 0.8;
}

.highlightText {
    color: var(--red-color-300);
}

.stamp_upload {
    min-height: 150px;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--primary-text-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

/* media */

@media (max-width:319px) {
    .customModalOne_body {
        padding: 10px;
    }

    .bodyInner {
        padding: 0px;
    }

    .close svg {
        font-size: 20px;
    }

    .customModalOne_body .header .title {
        font-size: 14px;
    }

    .customModalOne_body .header .description {
        font-size: 11px;
    }

    .customModalOne_body .cardGray {
        border-radius: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (min-width:320px) and (max-width:575px) {
    .customModalOne_body {
        padding: 10px;
    }

    .bodyInner {
        padding: 0px;
    }

    .close svg {
        font-size: 20px;
    }

    .customModalOne_body .header .title {
        font-size: 15px;
    }

    .customModalOne_body .header .description {
        font-size: 12px;
    }

    .customModalOne_body .cardGray {
        border-radius: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .customModalOne_body {
        padding: 1rem;
    }

    .bodyInner {
        padding: 20px;
    }

    .close svg {
        font-size: 25px;
    }

    .customModalOne_body .header .title {
        font-size: 16.5px;
    }

    .customModalOne_body .header .description {
        font-size: 13px;
    }

    .customModalOne_body .cardGray {
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .customModalOne_body {
        padding: 1rem;
    }

    .bodyInner {
        padding: 20px;
    }

    .close svg {
        font-size: 25px;
    }

    .customModalOne_body .header .title {
        font-size: 17px;
    }

    .customModalOne_body .header .description {
        font-size: 14px;
    }

    .customModalOne_body .cardGray {
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .customModalOne_body {
        padding: 1rem;
    }

    .bodyInner {
        padding: 25px;
    }

    .close svg {
        font-size: 30px;
    }

    .customModalOne_body .header .title {
        font-size: 18px;
    }

    .customModalOne_body .header .description {
        font-size: 15px;
    }

    .customModalOne_body .cardGray {
        border-radius: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (min-width:1200px) and (max-width:1399px) {
    .customModalOne_body {
        padding: 1rem;
    }

    .bodyInner {
        padding: 25px;
    }

    .close svg {
        font-size: 30px;
    }

    .customModalOne_body .header .title {
        font-size: 18px;
    }

    .customModalOne_body .header .description {
        font-size: 15px;
    }

    .customModalOne_body .cardGray {
        border-radius: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (min-width:1400px) and (max-width:1799px) {
    .customModalOne_body {
        padding: 1rem;
    }

    .bodyInner {
        padding: 30px;
    }

    .close svg {
        font-size: 30px;
    }

    .customModalOne_body .header .title {
        font-size: 20px;
    }

    .customModalOne_body .header .description {
        font-size: 15px;
    }

    .customModalOne_body .cardGray {
        border-radius: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .formButton {
        font-size: 17px;
    }
}

@media (min-width:1800px) {
    .customModalOne_body {
        padding: 1rem;
    }

    .customModalOne_body .header .title {
        font-size: 21px;
    }

    .customModalOne_body .header .description {
        font-size: 16px;
    }

    .customModalOne_body .cardGray {
        border-radius: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .formButton {
        font-size: 17px;
    }
}