.footer {
    background: var(--bg-color-1);
    height: 80px;
}

.footer .ul {
    margin-bottom: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    font-weight: 500;
}

.footer .ul li {
    list-style-type: none;
}

.link {
    text-decoration: none;
    color: var(--primary-text-color);
}

.link:hover {
    text-decoration: underline;
}

/* media */

@media (max-width:319px) {
    .link {
        font-size: 12px;
    }

    .footer .ul {
        gap: 15px;
    }
}

@media (min-width:320px) and (max-width:575px) {
    .link {
        font-size: 12px;
    }

    .footer .ul {
        gap: 15px;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .link {
        font-size: 14px;
    }

    .footer .ul {
        gap: 20px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .link {
        font-size: 15px;
    }

    .footer .ul {
        gap: 25px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .link {
        font-size: 15px;
    }

    .footer .ul {
        gap: 25px;
    }
}

@media (min-width:1200px) and (max-width:1399px) {
    .link {
        font-size: 15px;
    }

    .footer .ul {
        gap: 25px;
    }
}

@media (min-width:1400px) and (max-width:1599px) {
    .link {
        font-size: 16px;
    }

    .footer .ul {
        gap: 35px;
    }
}

@media (min-width:1600px) and (max-width:1799px) {
    .link {
        font-size: 16px;
    }

    .footer .ul {
        gap: 40px;
    }
}

@media (min-width:1800px) and (max-width:1920px) {
    .link {
        font-size: 17px;
    }

    .footer .ul {
        gap: 50px;
    }
}

@media (min-width:1921px) {
    .link {
        font-size: 17px;
    }

    .footer .ul {
        gap: 50px;
    }
}