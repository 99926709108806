.pageTitle {
    font-weight: 500;
    text-transform: uppercase;
}

.dashLeft .topCard {
    background: var(--bg-color-14);
}

.dashLeft .topCard .title {
    font-weight: 550;
}

.dashLeft .topCard .value {
    font-weight: 400;
}

.dashLeft .topCard .button {
    background: var(--bg-color-2);
    color: var(--theme-color);
    width: 100%;
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: 550;
    font-size: 15px;
}

.dashLeft .topCard .button:hover {
    opacity: 0.7;
}

.dashLeft .topCard .imageWrapper {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: var(--bg-color-15);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
}

.projectPipeline {
    background: var(--bg-color-14);
}

.projectPipeline .header .title {
    font-weight: 550;
}

.projectPipeline .table ul li {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.projectPipeline .table ul {
    padding: 0;
    margin: 0;
}

.projectPipeline .table ul li {
    list-style-type: none;
    border-bottom: 1px solid var(--border-color-2);
    white-space: nowrap;
    text-align: center;
}

.projectPipeline .table ul li:last-child {
    border-bottom: none;
}



.projectPipeline .table {
    max-width: 100%;
    overflow-x: scroll;
    padding-bottom: 10px;
}

.projectPipeline .table .tableWidth {
    width: 25%;
    min-width: 160px;
    border-right: 1px solid var(--border-color-2);
}

.projectPipeline .table .tableWidth:last-child {
    border-right: none;
}

.kpi {
    background: var(--bg-color-14);
}

.kpi .header .title {
    font-weight: 550;
}

.kpi .header button {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 14px;
    border-bottom: 1px solid transparent;
}

.kpi .header button:hover {
    text-decoration: underline;
}

.kpi .header button svg {
    font-size: 13px;
}

.kpi .kpiCard {
    background: var(--bg-color-2);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--theme-color);
}

.kpi .kpiCard {
    background: var(--bg-color-2);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--theme-color);
}

.kpi .kpiCard .textSm {
    font-weight: 450;
}

.dashRight {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.stageGraph {
    height: 50%;
    background: var(--bg-color-14);
}

.revenueGraph {
    height: 50%;
    background: var(--bg-color-14);
}

.dashRight .header .title {
    font-weight: 550;
}

.batch {
    font-weight: 550;
    font-size: 13px;
    background: var(--bg-color-1);
    border-radius: 10px;
    padding: 2px 8px;
}


/* media */

@media (max-width:319px) {
    .pageTitle {
        font-size: 19px;
    }

    .dashLeft .topCard {
        border-radius: 10px;
        padding: 15px;
    }

    .dashLeft .topCard .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .dashLeft .topCard .value {
        font-size: 28px;
    }

    .projectPipeline {
        border-radius: 10px;
        padding: 15px;
    }

    .projectPipeline .header .title {
        font-size: 16px;
    }

    .projectPipeline .table ul li {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .kpi {
        border-radius: 10px;
        padding: 15px;
    }

    .kpi .header .title {
        font-size: 16px;
    }

    .kpi .kpiCard {
        border-radius: 10px;
        padding: 25px 15px 15px 15px;
    }

    .kpi .kpiCard .textLg {
        font-size: 25px;
        line-height: 25px;
    }

    .kpi .kpiCard .textSm {
        font-size: 14px;
    }

    .graphInner {
        border-radius: 10px;
        padding: 15px;
    }

    .dashRight .header .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .subTitle {
        font-size: 15px;
    }
}

@media (min-width:320px) and (max-width:575px) {
    .pageTitle {
        font-size: 19px;
    }

    .dashLeft .topCard {
        border-radius: 10px;
        padding: 15px;
    }

    .dashLeft .topCard .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .dashLeft .topCard .value {
        font-size: 28px;
    }

    .projectPipeline {
        border-radius: 10px;
        padding: 15px;
    }

    .projectPipeline .header .title {
        font-size: 16px;
    }

    .projectPipeline .table ul li {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .kpi {
        border-radius: 10px;
        padding: 15px;
    }

    .kpi .header .title {
        font-size: 16px;
    }

    .kpi .kpiCard {
        border-radius: 10px;
        padding: 25px 15px 15px 15px;
    }

    .kpi .kpiCard .textLg {
        font-size: 25px;
        line-height: 25px;
    }

    .kpi .kpiCard .textSm {
        font-size: 14px;
    }

    .graphInner {
        border-radius: 10px;
        padding: 15px;
    }

    .dashRight .header .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .subTitle {
        font-size: 15px;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .pageTitle {
        font-size: 24px;
    }

    .dashLeft .topCard {
        border-radius: 10px;
        padding: 15px;
    }

    .dashLeft .topCard .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .dashLeft .topCard .value {
        font-size: 28px;
    }

    .projectPipeline {
        border-radius: 10px;
        padding: 15px;
    }

    .projectPipeline .header .title {
        font-size: 16px;
    }

    .projectPipeline .table ul li {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .kpi {
        border-radius: 10px;
        padding: 15px;
    }

    .kpi .header .title {
        font-size: 16px;
    }

    .kpi .kpiCard {
        border-radius: 10px;
        padding: 25px 15px 15px 15px;
    }

    .kpi .kpiCard .textLg {
        font-size: 25px;
        line-height: 25px;
    }

    .kpi .kpiCard .textSm {
        font-size: 14px;
    }

    .graphInner {
        border-radius: 10px;
        padding: 15px;
    }

    .dashRight .header .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .subTitle {
        font-size: 16px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .pageTitle {
        font-size: 24px;
    }

    .dashLeft .topCard {
        border-radius: 10px;
        padding: 15px;
    }

    .dashLeft .topCard .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .dashLeft .topCard .value {
        font-size: 28px;
    }

    .projectPipeline {
        border-radius: 10px;
        padding: 15px;
    }

    .projectPipeline .header .title {
        font-size: 16px;
    }

    .projectPipeline .table ul li {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .kpi {
        border-radius: 10px;
        padding: 15px;
    }

    .kpi .header .title {
        font-size: 16px;
    }

    .kpi .kpiCard {
        border-radius: 10px;
        padding: 25px 15px 15px 15px;
    }

    .kpi .kpiCard .textLg {
        font-size: 25px;
        line-height: 25px;
    }

    .kpi .kpiCard .textSm {
        font-size: 14px;
    }

    .graphInner {
        border-radius: 10px;
        padding: 15px;
    }

    .dashRight .header .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .subTitle {
        font-size: 16px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .pageTitle {
        font-size: 24px;
    }

    .dashLeft .topCard {
        border-radius: 10px;
        padding: 15px;
    }

    .dashLeft .topCard .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .dashLeft .topCard .value {
        font-size: 28px;
    }

    .projectPipeline {
        border-radius: 10px;
        padding: 15px;
    }

    .projectPipeline .header .title {
        font-size: 16px;
    }

    .projectPipeline .table ul li {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .kpi {
        border-radius: 10px;
        padding: 15px;
    }

    .kpi .header .title {
        font-size: 16px;
    }

    .kpi .kpiCard {
        border-radius: 10px;
        padding: 25px 15px 15px 15px;
    }

    .kpi .kpiCard .textLg {
        font-size: 25px;
        line-height: 25px;
    }

    .kpi .kpiCard .textSm {
        font-size: 14px;
    }

    .graphInner {
        border-radius: 10px;
        padding: 15px;
    }

    .dashRight .header .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .subTitle {
        font-size: 17px;
    }
}

@media (min-width:1200px) and (max-width:1399px) {
    .pageTitle {
        font-size: 24px;
    }

    .dashLeft .topCard {
        border-radius: 10px;
        padding: 15px;
    }

    .dashLeft .topCard .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .dashLeft .topCard .value {
        font-size: 28px;
    }

    .projectPipeline {
        border-radius: 10px;
        padding: 15px;
    }

    .projectPipeline .header .title {
        font-size: 16px;
    }

    .projectPipeline .table ul li {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .kpi {
        border-radius: 10px;
        padding: 15px;
    }

    .kpi .header .title {
        font-size: 16px;
    }

    .kpi .kpiCard {
        border-radius: 10px;
        padding: 25px 15px 15px 15px;
    }

    .kpi .kpiCard .textLg {
        font-size: 25px;
        line-height: 25px;
    }

    .kpi .kpiCard .textSm {
        font-size: 14px;
    }

    .graphInner {
        border-radius: 10px;
        padding: 15px;
    }

    .dashRight .header .title {
        font-size: 16px;
        white-space: nowrap;
    }

    .subTitle {
        font-size: 18px;
    }
}

@media (min-width:1400px) and (max-width:1799px) {
    .pageTitle {
        font-size: 28px;
    }

    .dashLeft .topCard {
        border-radius: 13px;
        padding: 25px;
    }

    .dashLeft .topCard .title {
        font-size: 20px;
        white-space: nowrap;
    }

    .dashLeft .topCard .value {
        font-size: 33px;
    }

    .projectPipeline {
        border-radius: 13px;
        padding: 25px;
    }

    .projectPipeline .header .title {
        font-size: 20px;
    }

    .projectPipeline .table ul li {
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .kpi {
        border-radius: 13px;
        padding: 25px;
    }

    .kpi .header .title {
        font-size: 20px;
    }

    .kpi .kpiCard {
        border-radius: 10px;
        padding: 35px 15px 15px 15px;
    }

    .kpi .kpiCard .textLg {
        font-size: 35px;
        line-height: 35px;
    }

    .kpi .kpiCard .textSm {
        font-size: 18px;
    }

    .dashRight {
        height: 100%;
    }



    .graphInner {
        border-radius: 13px;
        padding: 25px;
    }

    .dashRight .header .title {
        font-size: 20px;
        white-space: nowrap;
    }

    .subTitle {
        font-size: 20px;
    }
}



@media (min-width:1800px) {
    .pageTitle {
        font-size: 29px;
    }

    .dashLeft .topCard {
        border-radius: 13px;
        padding: 25px;
    }

    .dashLeft .topCard .title {
        font-size: 22px;
        white-space: nowrap;
    }

    .dashLeft .topCard .value {
        font-size: 38px;
    }

    .dashLeft .topCard .button {
        font-size: 17px;
    }

    .projectPipeline {
        border-radius: 13px;
        padding: 25px;
    }

    .projectPipeline .header .title {
        font-size: 22px;
    }

    .projectPipeline .table ul li {
        font-size: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .kpi {
        border-radius: 13px;
        padding: 25px;
    }

    .kpi .header .title {
        font-size: 22px;
    }

    .kpi .kpiCard {
        border-radius: 10px;
        padding: 35px 15px 15px 15px;
    }

    .kpi .kpiCard .textLg {
        font-size: 40px;
        line-height: 40px;
    }

    .kpi .kpiCard .textSm {
        font-size: 20px;
    }

    .graphInner {
        border-radius: 13px;
        padding: 25px;
    }

    .dashRight .header .title {
        font-size: 22px;
        white-space: nowrap;
    }

    .subTitle {
        font-size: 20px;
    }
}