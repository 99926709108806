.section {
    padding-top: 25px;
}

.container {
    max-width: 95% !important;
    padding: 0px !important;
}



.header .title {
    font-family: var(--montserrat-font);
    font-weight: 550;
    text-align: center;
}

.body {
    margin-top: 40px;
    font-family: var(--montserrat-font);
}

.body .right .heading {
    color: var(--text-color-2);
    font-weight: 800;
}

.body .right .subHeading {
    color: var(--text-color-2);
    font-weight: 500;
}


.body .form {
    font-weight: 550;
    display: flex;
    flex-direction: column;
}

.body .form label {
    font-weight: 550;
}

.body .form .error {
    color: var(--red-color-200);
    font-weight: 450;
}

.body .form .input {
    font-weight: 550;
    border: none;
    outline: none;
    border-radius: 10px;
    height: 45px;
    background: var(--bg-color-1);
    padding-left: 10px;
    padding-right: 10px;
}

.body .form .input::placeholder {
    font-weight: 400;
    color: var(--primary-text-color);
}

.password_container {
    display: flex;
    align-items: center;
}

.password_container {
    border: none;
    outline: none;
    border-radius: 10px;
    height: 45px;
    background: var(--bg-color-1);
    padding-left: 10px;
    padding-right: 10px;
}

.password_container input {
    display: flex;
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    height: 100%;
    font-weight: 550;
}

.password_container input::placeholder {
    font-weight: 400;
    color: var(--primary-text-color);
}

.password_container button {
    background: transparent;
    border: none;
    outline: none;
    margin-left: 15px;
}

.body .forms .button {
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-color-2);
    color: var(--theme-color);
    font-weight: 700;
    border-radius: 10px;
}

.body .right .links {
    display: flex;
    flex-direction: column;
}

.body .right .links a {
    text-align: center;
    color: var(--text-color-3);
    font-weight: 500;
    text-decoration: none;
}

.body .right .links a:hover {
    text-decoration: underline;
}

.body .forms .button:hover {
    opacity: 0.8;
}

.body .left .card {
    background: var(--bg-color-5);
}

.body .left .card p {
    text-align: center;
    color: var(--text-color-2);
}

.body .left .card .heading {
    font-weight: 800;
}

.body .left .card .subHeading {
    font-weight: 800;
}

.body .left .card .footer {
    font-weight: 500;
}

/* media */

@media (max-width:319px) {
    .header .title {
        font-size: 23px;
    }

    .header .logo {
        width: 100px;
    }

    .body .right .heading {
        font-size: 18px;
        line-height: 28px;
    }

    .body .right .subHeading {
        font-size: 12px;
        line-height: 22px;
    }

    .body .form label {
        font-size: 15px;
    }

    .body .form .input {
        font-size: 14px;
    }

    .body .form .error {
        font-size: 12px;
    }

    .password_container input {
        font-size: 14px;
    }

    .body .forms .button {
        font-size: 15px;
    }

    .body .right .links a {
        font-size: 15px;
    }

    .body .left {
        display: flex;
        justify-content: center;
    }

    .body .left .card {
        padding: 10px;
        border-radius: 20px;
        max-width: 500px;
    }

    .body .left .card .heading {
        font-size: 17px;
        line-height: 25px;
    }

    .body .left .card .subHeading {
        font-size: 11.5px;
        line-height: 25px;
    }

    .body .left .card .image {
        min-width: 100%;
        width: 100%;
        min-height: 330px;
        height: 330px;
        max-height: 330px;
        object-fit: cover;
        border-radius: 30px;
    }

    .body .left .card .footer {
        font-size: 15px;
    }
}

@media (min-width:320px) and (max-width:575px) {
    .header .title {
        font-size: 23px;
    }

    .header .logo {
        width: 100px;
    }

    .body .right .heading {
        font-size: 18px;
        line-height: 28px;
    }

    .body .right .subHeading {
        font-size: 12px;
        line-height: 22px;
    }

    .body .form label {
        font-size: 15px;
    }

    .body .form .input {
        font-size: 14px;
    }

    .body .form .error {
        font-size: 12px;
    }

    .password_container input {
        font-size: 14px;
    }

    .body .forms .button {
        font-size: 15px;
    }

    .body .right .links a {
        font-size: 15px;
    }

    .body .left {
        display: flex;
        justify-content: center;
    }

    .body .left .card {
        padding: 10px;
        border-radius: 20px;
        max-width: 500px;
    }

    .body .left .card .heading {
        font-size: 17px;
        line-height: 25px;
    }

    .body .left .card .subHeading {
        font-size: 11.5px;
        line-height: 25px;
    }

    .body .left .card .image {
        min-width: 100%;
        width: 100%;
        min-height: 330px;
        height: 330px;
        max-height: 330px;
        object-fit: cover;
        border-radius: 30px;
    }

    .body .left .card .footer {
        font-size: 15px;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .header .title {
        font-size: 25px;
    }

    .header .logo {
        width: 120px;
    }

    .body .right .heading {
        font-size: 22px;
        line-height: 32px;
    }

    .body .right .subHeading {
        font-size: 14px;
        line-height: 24px;
    }

    .body .form label {
        font-size: 16px;
    }

    .body .form .input {
        font-size: 15px;
    }

    .body .form .error {
        font-size: 13px;
    }

    .password_container input {
        font-size: 15px;
    }

    .body .forms .button {
        font-size: 16px;
    }

    .body .right .links a {
        font-size: 15px;
    }

    .body .left {
        display: flex;
        justify-content: center;
    }

    .body .left .card {
        padding: 30px 30px;
        border-radius: 40px;
        max-width: 500px;
    }

    .body .left .card .heading {
        font-size: 20px;
        line-height: 30px;
    }

    .body .left .card .subHeading {
        font-size: 15px;
        line-height: 25px;
    }

    .body .left .card .image {
        min-width: 100%;
        width: 100%;
        min-height: 420px;
        height: 420px;
        max-height: 420px;
        object-fit: cover;
        border-radius: 30px;
    }

    .body .left .card .footer {
        font-size: 15px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .header .title {
        font-size: 23px;
    }

    .header .logo {
        width: 100px;
    }

    .body .right .heading {
        font-size: 22px;
        line-height: 32px;
    }

    .body .right .subHeading {
        font-size: 14px;
        line-height: 24px;
    }

    .body .form label {
        font-size: 15px;
    }

    .body .form .input {
        font-size: 14px;
    }

    .body .form .error {
        font-size: 12px;
    }

    .password_container input {
        font-size: 14px;
    }

    .body .forms .button {
        font-size: 15px;
    }

    .body .right .links a {
        font-size: 15px;
    }

    .body .left {
        display: flex;
        justify-content: center;
    }

    .body .left .card {
        padding: 30px 30px;
        border-radius: 40px;
        max-width: 500px;
    }

    .body .left .card .heading {
        font-size: 20px;
        line-height: 30px;
    }

    .body .left .card .subHeading {
        font-size: 15px;
        line-height: 25px;
    }

    .body .left .card .image {
        min-width: 100%;
        width: 100%;
        min-height: 420px;
        height: 420px;
        max-height: 420px;
        object-fit: cover;
        border-radius: 30px;
    }

    .body .left .card .footer {
        font-size: 15px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .header .title {
        font-size: 23px;
    }

    .header .logo {
        width: 100px;
    }

    .body .right .heading {
        font-size: 22px;
        line-height: 32px;
    }

    .body .right .subHeading {
        font-size: 14px;
        line-height: 24px;
    }

    .body .form label {
        font-size: 15px;
    }

    .body .form .input {
        font-size: 14px;
    }

    .body .form .error {
        font-size: 12px;
    }

    .password_container input {
        font-size: 14px;
    }

    .body .forms .button {
        font-size: 15px;
    }

    .body .right .links a {
        font-size: 15px;
    }

    .body .left .card {
        padding: 30px 30px;
        border-radius: 40px;
    }

    .body .left .card .heading {
        font-size: 20px;
        line-height: 30px;
    }

    .body .left .card .subHeading {
        font-size: 15px;
        line-height: 25px;
    }

    .body .left .card .image {
        min-width: 100%;
        width: 100%;
        min-height: 420px;
        height: 420px;
        max-height: 420px;
        object-fit: cover;
        border-radius: 30px;
    }

    .body .left .card .footer {
        font-size: 15px;
    }
}

@media (min-width:1200px) and (max-width:1399px) {
    .header .title {
        font-size: 28px;
    }

    .header .logo {
        width: 110px;
    }

    .body .right .heading {
        font-size: 22px;
        line-height: 32px;
    }

    .body .right .subHeading {
        font-size: 14px;
        line-height: 24px;
    }

    .body .form label {
        font-size: 15px;
    }

    .body .form .input {
        font-size: 14px;
    }

    .body .form .error {
        font-size: 12px;
    }

    .password_container input {
        font-size: 14px;
    }

    .body .forms .button {
        font-size: 15px;
    }

    .body .right .links a {
        font-size: 15px;
    }

    .body .left .card {
        padding: 30px 50px;
        border-radius: 40px;
    }

    .body .left .card .heading {
        font-size: 24px;
        line-height: 35px;
    }

    .body .left .card .subHeading {
        font-size: 17px;
        line-height: 28px;
    }

    .body .left .card .image {
        min-width: 100%;
        width: 100%;
        min-height: 440px;
        height: 440px;
        max-height: 440px;
        object-fit: cover;
        border-radius: 30px;
    }

    .body .left .card .footer {
        font-size: 15px;
    }
}

@media (min-width:1400px) and (max-width:1599px) {
    .header .title {
        font-size: 32px;
    }

    .header .logo {
        width: 130px;
    }


    .body .right .heading {
        font-size: 23px;
        line-height: 33px;
    }

    .body .right .subHeading {
        font-size: 15px;
        line-height: 25px;
    }

    .body .form label {
        font-size: 16px;
    }

    .body .form .input {
        font-size: 15px;
    }

    .body .form .error {
        font-size: 14px;
    }

    .password_container input {
        font-size: 15px;
    }

    .body .forms .button {
        font-size: 16px;
    }

    .body .right .links a {
        font-size: 15px;
    }

    .body .left .card {
        padding: 30px 70px;
        border-radius: 40px;
    }

    .body .left .card .heading {
        font-size: 30px;
        line-height: 45px;
    }

    .body .left .card .subHeading {
        font-size: 20px;
        line-height: 45px;
    }

    .body .left .card .image {
        min-width: 100%;
        width: 100%;
        min-height: 450px;
        height: 450px;
        max-height: 450px;
        object-fit: cover;
        border-radius: 30px;
    }

    .body .left .card .footer {
        font-size: 17px;
    }
}

@media (min-width:1600px) and (max-width:1799px) {
    .header .title {
        font-size: 35px;
    }

    .header .logo {
        width: 130px;
    }

    .body .right .heading {
        font-size: 28px;
        line-height: 40px;
    }

    .body .right .subHeading {
        font-size: 17px;
        line-height: 30px;
    }

    .body .form label {
        font-size: 16px;
    }

    .body .form .input {
        font-size: 16px;
    }

    .body .form .error {
        font-size: 14px;
    }

    .password_container input {
        font-size: 16px;
    }

    .body .forms .button {
        font-size: 16px;
    }

    .body .right .links a {
        font-size: 16px;
    }

    .body .left .card {
        padding: 30px 70px;
        border-radius: 40px;
    }

    .body .left .card .heading {
        font-size: 30px;
        line-height: 45px;
    }

    .body .left .card .subHeading {
        font-size: 20px;
        line-height: 45px;
    }

    .body .left .card .image {
        min-width: 100%;
        width: 100%;
        min-height: 470px;
        height: 470px;
        max-height: 470px;
        object-fit: cover;
        border-radius: 30px;
    }

    .body .left .card .footer {
        font-size: 17px;
    }
}

@media (min-width:1800px) and (max-width:1920px) {
    .header .title {
        font-size: 40px;
    }

    .header .logo {
        width: 140px;
    }

    .body .right .heading {
        font-size: 28px;
        line-height: 40px;
    }

    .body .right .subHeading {
        font-size: 17px;
        line-height: 30px;
    }

    .body .form label {
        font-size: 17px;
    }

    .body .form .input {
        font-size: 17px;
    }

    .body .form .error {
        font-size: 15px;
    }

    .password_container input {
        font-size: 17px;
    }

    .body .forms .button {
        font-size: 17px;
    }

    .body .right .links a {
        font-size: 16px;
    }

    .body .left .card {
        padding: 30px 70px;
        border-radius: 40px;
    }

    .body .left .card .heading {
        font-size: 35px;
        line-height: 48px;
    }

    .body .left .card .subHeading {
        font-size: 25px;
        line-height: 45px;
    }

    .body .left .card .image {
        min-width: 100%;
        width: 100%;
        min-height: 480px;
        height: 480px;
        max-height: 480px;
        object-fit: cover;
        border-radius: 30px;
    }

    .body .left .card .footer {
        font-size: 19px;
    }
}

@media (min-width:1921px) {
    .header .title {
        font-size: 40px;
    }

    .header .logo {
        width: 140px;
    }



    .body .form label {
        font-size: 17px;
    }

    .body .form .input {
        font-size: 17px;
    }

    .body .form .error {
        font-size: 15px;
    }

    .password_container input {
        font-size: 17px;
    }

    .body .forms .button {
        font-size: 17px;
    }
}