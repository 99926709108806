.thankyou {
    padding-top: 40px;
    padding-bottom: 40px;
    font-family: var(--montserrat-font);
    min-height: calc(100vh - 100px);
}

.thankyou .card {
    background: var(--bg-color-3);
    box-shadow: 0px 4px 6px 0px #0000001A;

}

.thankyou .card h5 {
    font-weight: 600;
}

.thankyou .card p {
    font-weight: 550;
}

.detail_button {
    background: var(--orange-color-200);
    border: none;
    outline: none;
    padding: 10px 25px;
    color: var(--theme-color);
    font-weight: 700;
    border-radius: 15px;
}

.detail_button:hover {
    opacity: 0.8;
}

/* media */

@media (max-width:319px) {
    .thankyou .card {
        padding: 15px;
        border-radius: 15px;
    }

    .thankyou .card h5 {
        font-size: 20px;
        line-height: 30px;
    }

    .thankyou .card p {
        font-size: 15px;
        line-height: 25px;
    }

    .detail_button {
        font-size: 15px;
    }
}


@media (min-width:320px) and (max-width:575px) {
    .thankyou .card {
        padding: 15px;
        border-radius: 15px;
    }

    .thankyou .card h5 {
        font-size: 20px;
        line-height: 30px;
    }

    .thankyou .card p {
        font-size: 15px;
        line-height: 25px;
    }

    .detail_button {
        font-size: 15px;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .thankyou .card {
        padding: 35px;
        border-radius: 20px;
    }

    .thankyou .card h5 {
        font-size: 23px;
        line-height: 35px;
    }

    .thankyou .card p {
        font-size: 15px;
        line-height: 25px;
    }

    .detail_button {
        font-size: 15px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .thankyou .card {
        padding: 35px;
        border-radius: 20px;
    }

    .thankyou .card h5 {
        font-size: 30px;
        line-height: 42px;
    }

    .thankyou .card p {
        font-size: 15.5px;
        line-height: 25px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .thankyou .card {
        padding: 35px;
        border-radius: 20px;
    }

    .thankyou .card h5 {
        font-size: 30px;
        line-height: 42px;
    }

    .thankyou .card p {
        font-size: 15.5px;
        line-height: 25px;
    }
}

@media (min-width:1200px) and (max-width:1399px) {
    .thankyou .card {
        padding: 35px;
        border-radius: 20px;
    }

    .thankyou .card h5 {
        font-size: 33px;
        line-height: 42px;
    }

    .thankyou .card p {
        font-size: 15.5px;
        line-height: 29px;
    }
}

@media (min-width:1400px) and (max-width:1599px) {
    .thankyou .card {
        padding: 35px;
        border-radius: 20px;
    }

    .thankyou .card h5 {
        font-size: 32px;
        line-height: 42px;
    }

    .thankyou .card p {
        font-size: 15.5px;
        line-height: 29px;
    }
}

@media (min-width:1600px) and (max-width:1799px) {
    .thankyou .card {
        padding: 35px;
        border-radius: 20px;
    }

    .thankyou .card h5 {
        font-size: 32px;
        line-height: 42px;
    }

    .thankyou .card p {
        font-size: 15.5px;
        line-height: 29px;
    }
}

@media (min-width:1800px) and (max-width:1920px) {
    .thankyou .card {
        padding: 35px;
        border-radius: 20px;
    }

    .thankyou .card h5 {
        font-size: 34px;
        line-height: 46px;
    }

    .thankyou .card p {
        font-size: 16px;
        line-height: 30px;
    }
}

@media (min-width:1921px) {
    .thankyou .card {
        padding: 35px;
        border-radius: 20px;
    }

    .thankyou .card h5 {
        font-size: 34px;
        line-height: 46px;
    }

    .thankyou .card p {
        font-size: 16px;
        line-height: 30px;
    }
}