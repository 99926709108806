.pageTitle {
    font-weight: 500;
    text-transform: uppercase;
}

.Dashboard {
    font-family: var(--roboto-font);
}

.layout {
    display: flex;
}

.sidebar {
    color: var(--theme-color);
}

.section_title {
    color: var(--primary-text-color);
    font-family: var(--roboto-font);
    font-weight: 500;
    font-size: 22px;

}

.stage_gates .cards {
    box-shadow: 0px 4px 6px 0px #0000001A;
    padding: 20px;
    border-radius: 10px;
}

.stage_gates .cards .status {
    min-width: 20px;
    width: 20px;
    max-width: 20px;
    min-height: 20px;
    height: 20px;
    max-height: 20px;
    border-radius: 50%;
    border: 1px solid var(--bg-color-4);
}

.stage_gates .cards .status.received {
    background: var(--green-color-2);
}

.stage_gates .cards .status.review {
    background: var(--orange-color-200);
}

.stage_gates .cards .title {
    font-weight: 700;
    font-size: 16px;
}

.stage_gates .cards .subTitle {
    font-size: 14px;
}

/* .project_table table tr {
    background-color: rgb(165, 182, 229);
    box-shadow: 0px 2px 2px black;
    -moz-box-shadow: 0px 2px 2px black;
    -webkit-box-shadow: 0px 2px 2px black;
} */
.stage_gates .cards .applicationActions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.stage_gates .cards .applicationActions button {
    border: none;
    outline: none;
    background: transparent;
}


/* media */

@media (max-width:319px) {
    .pageTitle {
        font-size: 19px;
    }


}

@media (min-width:320px) and (max-width:575px) {
    .pageTitle {
        font-size: 19px;
    }


}

@media (min-width:576px) and (max-width:767px) {
    .pageTitle {
        font-size: 24px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .pageTitle {
        font-size: 24px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .pageTitle {
        font-size: 24px;
    }
}

@media (min-width:1200px) and (max-width:1399px) {
    .pageTitle {
        font-size: 24px;
    }

}

@media (min-width:1400px) and (max-width:1799px) {
    .pageTitle {
        font-size: 28px;
    }
}

@media (min-width:1800px) {
    .pageTitle {
        font-size: 29px;
    }
}