.header {
    height: 60px;
    padding: 20px;
    background: var(--bg-color-3);
    box-shadow: 0px 0px 0px 0px #00000014;
    font-family: var(--montserrat-font);
}

.logo {
    display: flex;
    align-items: center;
    gap: 10px;

}

.logo .name {
    font-weight: 600;
}

/* media */

@media (max-width:319px) {}

.logo img {
    width: 16px;
}

.logo .name {
    font-size: 15px;
}

@media (min-width:320px) and (max-width:575px) {
    .logo img {
        width: 16px;
    }

    .logo .name {
        font-size: 15px;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .logo img {
        width: 16px;
    }

    .logo .name {
        font-size: 15px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .logo img {
        width: 18px;
    }

    .logo .name {
        font-size: 16px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .logo img {
        width: 18px;
    }

    .logo .name {
        font-size: 16px;
    }
}

@media (min-width:1200px) and (max-width:1399px) {
    .logo img {
        width: 18px;
    }

    .logo .name {
        font-size: 16px;
    }
}

@media (min-width:1400px) and (max-width:1599px) {
    .logo img {
        width: 18px;
    }

    .logo .name {
        font-size: 16px;
    }
}

@media (min-width:1600px) and (max-width:1799px) {
    .logo img {
        width: 18px;
    }

    .logo .name {
        font-size: 16px;
    }
}

@media (min-width:1800px) and (max-width:1920px) {
    .logo img {
        width: 20px;
    }

    .logo .name {
        font-size: 18px;
    }
}

@media (min-width:1921px) {
    .logo img {
        width: 20px;
    }

    .logo .name {
        font-size: 18px;
    }
}