.footer {
    background: var(--bg-color-1);
    padding: 20px;
}

.detailedHeader .text_medium {
    font-weight: 500;
}

.detailedHeader .text_regular {
    font-size: 14px;
}

.left .p_black {
    color: var(--primary-text-color);
    font-weight: 450;
}

.left .p_blue {
    color: var(--blue-color-1);
    font-weight: 450;
}

.right img {
    width: 15px;
}

/* media */

@media (max-width:319px) {
    .container {
        max-width: 97% !important;
        padding: 0px !important;
    }
}

@media (min-width:320px) and (max-width:575px) {
    .container {
        max-width: 97% !important;
        padding: 0px !important;
    }


}

@media (min-width:576px) and (max-width:767px) {
    .container {
        max-width: 97% !important;
        padding: 0px !important;
    }


}

@media (min-width:768px) and (max-width:991px) {
    .container {
        max-width: 97% !important;
        padding: 0px !important;
    }


}

@media (min-width:992px) and (max-width:1199px) {
    .container {
        max-width: 97% !important;
        padding: 0px !important;
    }


}

@media (min-width:1200px) and (max-width:1399px) {
    .container {
        max-width: 97% !important;
        padding: 0px !important;
    }



}

@media (min-width:1400px) and (max-width:1599px) {
    .container {
        max-width: 97% !important;
        padding: 0px !important;
    }


}

@media (min-width:1600px) and (max-width:1799px) {
    .container {
        max-width: 97% !important;
        padding: 0px !important;
    }


}

@media (min-width:1800px) and (max-width:1920px) {
    .container {
        max-width: 97% !important;
        padding: 0px !important;
    }

}

@media (min-width:1921px) {
    .container {
        max-width: 1850px !important;
        padding: 0px !important;
    }


}