@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --theme-color: #FFFFFF;

  --primary-text-color: #030303;

  --dark-color: #000;

  --text-color-2: #2B2C2B;
  --text-color-3: #6A6A6A;
  --text-color-4: #858585;
  --text-color-5: #7F7F7F;

  --blue-color-1: #3E5264;


  --bg-color-1: #E6E6E6;
  --bg-color-2: #828282;
  --bg-color-3: #F8F8F8;
  --bg-color-4: #bbbbbb;
  --bg-color-5: #F5F5F5;
  --bg-color-6: #505050;
  --bg-color-7: #E5E7EB;
  --bg-color-8: #E1E1E1;
  --bg-color-9: #21338E;
  --bg-color-10: #E5E5E5;
  --bg-color-11: #176EF3;
  --bg-color-12: #D3D3D3;
  --bg-color-13: #7F7F7F;
  --bg-color-14: #f0f0f0;
  --bg-color-15: #EAEAEA;


  --red-color-200: rgb(255, 0, 0);
  --red-color-300: #FF3B30;
  --red-color-400: rgb(129, 31, 26);


  --green-color-1: #286E34;
  --green-color-2: #4CD964;
  --green-color-3: #2a8e21;

  --orange-color-200: #FF9500;

  --border-color-1: #03030373;
  --border-color-2: #c1c1c1;
  --border-color-3: #717671;

  --sidebar_xs: 65px;
  --sidebar_lg: 250px;
  --header_height: 70px;

  --montserrat-font: "Montserrat", sans-serif;
  --roboto-font: "Roboto", sans-serif;
  --open-sans-font: "Open Sans", sans-serif;
  --poppins-font: "Poppins", sans-serif;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--theme-color) !important;
  color: var(--primary-text-color) !important;
  font-family: var(--roboto-font) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px !important;
}

/* start of scrollbar css */

::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--bg-color-4);
  border-radius: 10px;
}

.dash_sidebar::-webkit-scrollbar {
  width: 3px;
}

/* end of scrollbar css */


/* start of custom select one */

.select-one__indicator-separator {
  display: none;
}

.select-one__control {
  background: var(--bg-color-1) !important;
  border: none !important;
  border-radius: 10px !important;
  height: 45px !important;
}

.select-one__placeholder {
  color: var(--primary-text-color) !important;
  font-weight: 400 !important;
}

.select-one__indicator svg {
  color: var(--primary-text-color) !important;
}

.select-one__single-value {
  color: var(--primary-text-color) !important;
  font-weight: 550 !important;
}

.select-one__menu {
  background: var(--bg-color-1) !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.select-one__menu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.select-one__option--is-selected {
  background: var(--bg-color-2) !important
}

.select-one__option--is-focused {
  background: var(--bg-color-4) !important
}

.select-one__option {
  cursor: pointer !important;
}

.select-one__control--is-focused,
.select-one__control--menu-is-open {
  box-shadow: none !important;
}


/* end of custom select one */

/* start of custom select two */

.select-two__indicator-separator {
  display: none;
}

.select-two__control {
  background: transparent !important;
  border: 1px solid var(--bg-color-10) !important;
  border-radius: 10px !important;
  height: 45px !important;

}

.select-two__placeholder {
  color: var(--primary-text-color) !important;
  font-weight: 400 !important;
}

.select-two__indicator svg {
  color: var(--primary-text-color) !important;
}

.select-two__single-value {
  color: var(--primary-text-color) !important;
  font-weight: 550 !important;
}

.select-two__menu {
  background: var(--bg-color-1) !important;

  box-shadow: none !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.select-two__menu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.select-two__option--is-selected {
  background: var(--bg-color-2) !important
}

.select-two__option--is-focused {
  background: var(--bg-color-4) !important
}

.select-two__option {
  cursor: pointer !important;
}

.select-two__control--is-focused,
.select-two__control--menu-is-open {
  box-shadow: none !important;
}


/* end of custom select two */


/* start of custom select three */

.select-three__indicator-separator {
  display: none;
}

.select-three__control {
  background: var(--bg-color-1) !important;
  border: none !important;
  border-radius: 10px !important;
  min-height: 30px !important;
  height: 30px !important;
  min-width: 110px !important;
}

.select-three__placeholder {
  color: var(--primary-text-color) !important;
  font-weight: 400 !important;
}

.select-three__indicator {
  padding: 0px 3px !important;
}

.select-three__indicator svg {
  color: var(--primary-text-color) !important;
}

.select-three__single-value {
  color: var(--primary-text-color) !important;
  font-weight: 550 !important;
}

.select-three__menu {
  background: var(--bg-color-1) !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.select-three__menu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.select-three__option--is-selected {
  background: var(--bg-color-2) !important
}

.select-three__option--is-focused {
  background: var(--bg-color-4) !important
}

.select-three__option {
  cursor: pointer !important;
  font-size: 12px !important;
}

.select-three__control--is-focused,
.select-three__control--menu-is-open {
  box-shadow: none !important;
}

.select-three__value-container {
  font-size: 12px !important;
}

.select-three__menu-list {
  max-height: 150px !important;
}

/* end of custom select three */


/* start of custom checkbox - 1 */

.checkbox_container {
  display: block;
  position: relative;
  padding-left: 18px;
  /* margin-bottom: 18px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.static_table--checkbox .checkbox_container {
  margin-bottom: 22px;
}

.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox_container .checkbox_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  /* background: var(--theme-color); */
  border-radius: 2px;
  border: 2px solid var(--text-color-2);
}


.checkbox_container input:checked~.checkbox_checkmark {
  background: transparent;
}

.checkbox_container .checkbox_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


.checkbox_container .checkbox_checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid var(--primary-text-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* end of custom checkbox - 1 */

/* start of custom checkbox - 2 */

.check_container .checkbox_container {
  display: block;
  position: relative;
  padding-left: 18px;
  /* margin-bottom: 18px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check_container .checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check_container .checkbox_container .checkbox_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  /* background: var(--theme-color); */
  border-radius: 6px;
  border: 1px solid var(--text-color-2);
}


.check_container .checkbox_container input:checked~.checkbox_checkmark {
  background: transparent;
}

.check_container .checkbox_container .checkbox_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check_container .checkbox_container input:checked~.checkbox_checkmark:after {
  display: block;
}

.check_container .checkbox_container .checkbox_checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid var(--primary-text-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* end of custom checkbox -2 */

/* start of custom checkbox - 3 */

.check_container.check_container_sm .checkbox_container {
  display: block;
  position: relative;
  padding-left: 18px;
  margin-bottom: 18px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check_container.check_container_sm .checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check_container.check_container_sm .checkbox_container .checkbox_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  /* background: var(--theme-color); */
  border-radius: 6px;
  border: 1px solid var(--text-color-2);
}


.check_container.check_container_sm .checkbox_container input:checked~.checkbox_checkmark {
  background: transparent;
}

.check_container.check_container_sm .checkbox_container .checkbox_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check_container.check_container_sm .checkbox_container input:checked~.checkbox_checkmark:after {
  display: block;
}

.check_container.check_container_sm .checkbox_container .checkbox_checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid var(--primary-text-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* end of custom checkbox - 3 */

/* start of custom checkbox - 4 */

.check_container.table_checkbox .checkbox_container {
  display: block;
  position: relative;
  padding-left: 18px;
  /* margin-bottom: 22px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check_container.table_checkbox .checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check_container.table_checkbox .checkbox_container .checkbox_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 24px;
  border-radius: 4px;
  border: 1px solid var(--bg-color-9);
}


.check_container.table_checkbox .checkbox_container input:checked~.checkbox_checkmark {
  background: transparent;
}

.check_container.table_checkbox .checkbox_container .checkbox_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check_container.table_checkbox .checkbox_container input:checked~.checkbox_checkmark:after {
  display: block;
}

.check_container.table_checkbox .checkbox_container .checkbox_checkmark:after {
  left: 9px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid var(--bg-color-9);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* end of custom checkbox - 4 */

/* start of custom checkbox - 5 */

.check_container.home_checkbox .checkbox_container {
  display: block;
  position: relative;
  padding-left: 18px;
  /* margin-bottom: 22px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check_container.home_checkbox .checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check_container.home_checkbox .checkbox_container .checkbox_checkmark {
  position: absolute;
  top: -9px;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  border: 2px solid var(--text-color-2);
}


.check_container.home_checkbox .checkbox_container input:checked~.checkbox_checkmark {
  background: transparent;
}

.check_container.home_checkbox .checkbox_container .checkbox_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check_container.home_checkbox .checkbox_container input:checked~.checkbox_checkmark:after {
  display: block;
}

.check_container.home_checkbox .checkbox_container .checkbox_checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid var(--text-color-2);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* end of custom checkbox - 5 */

/* start of custom checkbox - 6 */

.green_check_container .checkbox_container {
  display: block;
  position: relative;
  padding-left: 18px;
  /* margin-bottom: 22px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.green_check_container .checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.green_check_container .checkbox_container .checkbox_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  border-radius: 4px;
  border: 1px solid var(--border-color-3);
}


.green_check_container .checkbox_container input:checked~.checkbox_checkmark {
  background: transparent;
}

.green_check_container .checkbox_container .checkbox_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.green_check_container .checkbox_container input:checked~.checkbox_checkmark:after {
  display: block;
}

.green_check_container .checkbox_container .checkbox_checkmark:after {
  left: 9px;
  top: 1px;
  width: 10px;
  height: 18px;
  border: solid var(--green-color-3);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* end of custom checkbox - 6 */

/* start of datepicker -1 */

.datepicker-one {
  background: var(--bg-color-1);
  padding: 2px 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 110px;
  height: 30px;
}



.datepicker-one .react-datepicker__input-container input {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 12px;
}


/* end of datepicker -1  */

/* start of table  */

.table-wid__55 {
  min-width: 55px !important;
  width: 55px !important;
  max-width: 55px !important;
}

.table-wid__230 {
  min-width: 230px !important;
}

.table-wid__100 {
  min-width: 100px !important;
}

.table-wid__150 {
  min-width: 150px !important;
}

.table-wid__180 {
  min-width: 180px !important;
}

.card_table {
  box-shadow: 0px 1px 12px 0px #03030314;
  overflow: hidden;
  padding-bottom: 10px;
}

.card_table .table-body.asrt-table-body {
  overflow: scroll;
  margin-top: 0px !important;
}

.card_table .table {
  background: transparent !important;
}

.card_table .table tr {
  border: none;
}

.card_table .table tr th {
  vertical-align: middle;
  background: transparent !important;
  color: var(--primary-text-color);
  border: none;
  border-bottom: 1px solid var(--bg-color-8);
  font-size: 16px;
  font-weight: 400;
  font-family: var(--roboto-font);
}

.card_table .table.table-bordered tbody tr td {
  background: transparent;
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
  color: var(--black18);
  font-size: 14px;
}

.card_table .row.table-foot.asrt-table-foot {
  margin: 10px;
  /* border: 1px solid var(--green2); */
  border-radius: 5px;
  background: transparent;
}

.card_table .row.table-foot.asrt-table-foot .col-md-6:first-child {
  align-items: center;
  display: flex;
  color: var(--primary-text-color);
}

.card_table .row.table-foot.asrt-table-foot .pagination {
  border: none !important;
  /* background: var(--themeLinear); */
  width: fit-content;
  margin-left: auto;
  border-radius: 0.375rem;
  font-weight: 500;
}

.card_table .row.table-foot.asrt-table-foot .pagination .page-link {
  background: transparent;
  color: var(--primary-text-color);
}

.card_table .row.table-foot.asrt-table-foot .pagination .page-link:hover {
  color: var(--primary-text-color);
}

.card_table .row.table-foot.asrt-table-foot .pagination .page-link input {
  background: transparent;
  color: var(--primary-text-color);
  box-shadow: none;
  outline: none;
}

.card_table .row.table-foot.asrt-table-foot .pagination .disabled.page-item .page-link {
  opacity: 0.7;
}

.card_table .table-striped>tbody>tr:nth-of-type(odd)>* {
  background: transparent !important;
}

.card_table .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: none !important;
}

/* end of table */

/* start of static table */

.static-table {
  box-shadow: 0px 1px 12px 0px #03030314;
  font-family: var(--roboto-font);
  padding: 10px;
}

.static-table thead tr th {
  vertical-align: top;
  padding: 10px 30px;
}

.static-table tbody tr td {
  border: none;
  padding: 15px 30px;
  vertical-align: middle;
}

.table-wid__55 {
  min-width: 55px !important;
  width: 55px !important;
  max-width: 55px !important;
}

.table-wid__250 {
  min-width: 250px !important;
}

.table-wid__230 {
  min-width: 230px !important;
}

.table-wid__100 {
  min-width: 100px !important;
}

.table-wid__150 {
  min-width: 150px !important;
}

.table-wid__180 {
  min-width: 180px !important;
}

.table-wid__200 {
  min-width: 200px !important;
}

.table-head__medium {
  font-weight: 400 !important;
  font-size: 16px;
}

.table-head__medium-gray {
  font-weight: 400 !important;
  font-size: 16px;
  color: var(--text-color-5);
}

.table-head__regular {
  font-weight: 400 !important;
  font-size: 14px;
}

.table-head__small {
  font-weight: 400 !important;
  font-size: 13px;
}

.table-body__regular {
  font-weight: 400 !important;
  font-size: 15px;
}

.static-table__pagination-details {
  font-family: var(--poppins-font);
  color: var(--text-color-4);
  font-weight: 450;
}

.static-table__pagination-buttons button {
  background: transparent;
  border: none;
  outline: none;
}


/* end of static table */

/* start of static table -02 */

.static-table-2 {
  font-family: var(--roboto-font);
}

.static-table-2 thead tr th {
  vertical-align: top;
  padding: 10px 30px;
}

.static-table-2 tbody tr td {
  border: none;
  padding: 15px 30px;
  vertical-align: middle;
}


.static-table-2__pagination-details {
  font-family: var(--poppins-font);
  color: var(--text-color-4);
  font-weight: 450;
}

.static-table-2__pagination-buttons button {
  background: transparent;
  border: none;
  outline: none;
}

.table-action__buttons button {
  background: transparent;
  border: none;
  outline: none;
}


/* end of static table -02 */

/* start of static table -03 */

.static-table-3 {
  font-family: var(--montserrat-font);
}

.static-table-3 thead tr th {
  vertical-align: top;
  padding: 10px 30px;
  font-weight: 500;
}

.static-table-3 tbody tr td {
  border: none;
  padding: 15px 30px;
  /* vertical-align: middle; */
  /* box-shadow: 10px 0px 6px 0px #00000029; */
}

.static-table-3 tbody tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

}

.static-table-3__pagination-details {
  font-family: var(--poppins-font);
  color: var(--text-color-4);
  font-weight: 450;
}

.static-table-3__pagination-buttons button {
  background: transparent;
  border: none;
  outline: none;
}



.button__orange {
  background: var(--orange-color-200);
  color: var(--theme-color);
  font-weight: 500;
  padding: 5px 15px;
  border: none;
  outline: none;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
}

.button__orange:hover {
  opacity: 0.8;
}

.table-head__bold {
  font-weight: 500 !important;
  font-size: 16px;
}

.table-body__bold {
  font-weight: 500 !important;
  font-size: 15px;
}

/* end of static table -03 */

.sidebar_navs ul li a.active .nav_items {
  background: var(--theme-color);
  color: var(--primary-text-color);
}

.sidebar_navs ul li a.active .nav_items img {
  filter: invert(1);
}

.sidebar_navs ul li a:hover .nav_items {
  background: var(--theme-color);
  color: var(--primary-text-color);
}

.sidebar_navs ul li a:hover .nav_items img {
  filter: invert(1);
}

.sidebar_navs .image_background {
  background: var(--theme-color);
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.sidebar_navs ul li a.active .image_background {
  background: var(--primary-text-color);
}

.sidebar_navs ul li a:hover .image_background {
  background: var(--primary-text-color);
}

.sidebar_navs .image_background img {
  filter: invert(1);
}

.sidebar_navs ul li a.active .image_background img {
  filter: unset;
}

.sidebar_navs ul li a:hover .image_background img {
  filter: unset;
}

.sidebar_navs::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.sidebar_navs::-webkit-scrollbar-thumb {
  background: #b0b0b0;
}


/* fotoer */

.footer_navs ul li a.active .nav_items {
  background: var(--theme-color);
  color: var(--primary-text-color);
}

.footer_navs ul li a.active .nav_items img {
  filter: invert(1);
}

.footer_navs ul li a:hover .nav_items {
  background: var(--theme-color);
  color: var(--primary-text-color);
}

.footer_navs ul li a:hover .nav_items img {
  filter: invert(1);
}

.footer_navs .image_background {
  background: var(--theme-color);
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.footer_navs ul li a.active .image_background {
  background: var(--primary-text-color);
}

.footer_navs ul li a:hover .image_background {
  background: var(--primary-text-color);
}

.footer_navs .image_background img {
  filter: invert(1);
}

.footer_navs ul li a.active .image_background img {
  filter: unset;
}

.footer_navs ul li a:hover .image_background img {
  filter: unset;
}


/* start of offcanva */


.offcanvas.custom_offcanva {
  background: var(--bg-color-11);
  max-width: 250px !important;
  padding: 20px;
}

.canva__button-close {
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  right: 10px;
  top: 10px;
}

/* end of offcanva */


/* start of context */

/* start of sidebar css */

.dash_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--bg-color-11);
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  z-index: 10;
  transition: 0.3s;
  /* border-bottom-right-radius: 20px; */
}

.dash_sidebar_content {
  transition: 0.3s;
  width: var(--sidebar_xs);
  box-shadow: var(--side_box_shadow);
  /* border-bottom-right-radius: 20px; */
}

.dash_sidebar_content.active {
  width: var(--sidebar_lg);
}

.sidebar_body {
  height: calc(100vh - var(--header_height));
  border-bottom-right-radius: 20px;
}

.sidebar_header {
  background: var(--darkGrayClr);
  height: var(--header_height);
  border-bottom-right-radius: 20px;
}

.sidebar_logo {
  width: 150px;
}

.sidebar_controller_button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  outline: none;
  background: var(--green_linear);
}

.sidebar_body {
  min-height: calc(100vh - var(--header_height));
  height: calc(100vh - var(--header_height));
  max-height: calc(100vh - var(--header_height));
  border-bottom-right-radius: 20px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.sidebar_name {
  white-space: nowrap;
}

.sidebar_separate {
  height: 1px;
  background: var(--theme-color);
}

.dash_sidebar_content.active .sidebar_separate {
  background: var(--bg-color-11);
}

/* .dash_sidebar_content.active .sidebar_separate {
  background: var(--bg-color-11);
} */

.sidebar_navlink .sidebar__linkOnly {
  padding-top: 10px;
  padding-bottom: 10px;
}

.sidebar_navlink .sidebar__linkOnly img {
  /* width: 25px; */
}

.sidebar_navlink .sidebar__linkOnly .sidebar_name {
  color: var(--theme-color);
  font-size: 16px;
}

.sidebar_navlink.active .sidebar__linkOnly {
  background-color: var(--theme-color) !important;
  border-radius: 10px;
}

.sidebar_navlink.active .sidebar__linkOnly img {
  filter: invert(1);
}

.sidebar_navlink.active .sidebar__linkOnly .sidebar_name {
  color: var(--dark-color);
}

.sidebar_navlink:hover .sidebar__linkOnly {
  background-color: var(--theme-color) !important;
  border-radius: 10px;
}

.sidebar_navlink:hover .sidebar__linkOnly img {
  filter: invert(1);
}

.sidebar_navlink:hover .sidebar__linkOnly .sidebar_name {
  color: var(--dark-color);
}

/* .sidebar_navlink:hover .sidebar__greenLine {
  background-color: var(--greenClr);
  border-radius: 30px;
} */
.sidebar_navlink .sidebar__greenLine {
  position: absolute;
  height: 42.5px;
  width: 5px;
  background-color: transparent;
  left: 0;
  top: 0;
}

/* .sidebar_navlink.active .sidebar__greenLine {
  position: absolute;
  border-radius: 5px;
  height: 42.5px;
  width: 5px;
  background-color: red;
} */
.sidebar_bottom_title {
  color: var(--theme-color);
  font-family: var(--roboto-font);
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
}

.dash_sidebar_content.active .sidebar_bottom_title {
  color: var(--theme-color);
  font-family: var(--roboto-font);
  font-weight: 700;
  text-transform: uppercase;
  opacity: 1;
}



.sidebar_iconBg {
  background: var(--theme-color);
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar_navlink .sidebar__linkOnly .sidebar_iconBg img {
  filter: invert(1);
}

.sidebar_navlink:hover .sidebar__linkOnly .sidebar_iconBg {
  background-color: var(--dark-color) !important;
}

.sidebar_navlink:hover .sidebar__linkOnly .sidebar_iconBg img {
  filter: unset;
}

.sidebar_navlink.active .sidebar__linkOnly .sidebar_iconBg {
  background-color: var(--dark-color) !important;
}

.sidebar_navlink.active .sidebar__linkOnly .sidebar_iconBg img {
  filter: unset;
}

.sidebar_profile_imgWrapper {
  min-width: 50px;
  width: 50px;
  max-width: 50px;
  min-height: 50px;
  height: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 50%;
  /* background-color: red; */
}

.sidebar_profile_imgWrapper img {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.dash_sidebar_content .sidebar_customerCard {
  display: none;
}

.dash_sidebar_content.active .sidebar_customerCard {
  background: var(--green-color-1);
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding: 15px;
  color: var(--theme-color);
}

.sidebar_customerCard {
  background: var(--green-color-1);
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding: 15px;
  color: var(--theme-color);
}

.sidebar_customerCard .title {
  font-size: 15px;
}

.sidebar_customerCard .subtitle {
  font-size: 13px;
}

/* end of sidebar css */

/* start of mainbar */

.dash_header {
  height: var(--header_height);
  background: var(--theme-color);
  position: fixed;
  width: calc(100% - var(--sidebar_xs));
  margin-left: var(--sidebar_xs);
  transition: 0.3s;
  top: 0;
  left: 0;
  z-index: 9;
}


.dash_mainbar {
  width: 100%;
  transition: 0.3s;
  padding-top: var(--header_height);
}

.width_full {
  width: calc(100% - var(--sidebar_xs));
  margin-left: var(--sidebar_xs);
  transition: 0.3s;
}

.width_less {
  width: calc(100% - var(--sidebar_xs));
  margin-left: var(--sidebar_xs);
  transition: 0.3s;
}

.dash_header {
  height: 70px;
}

.header-profile__wrapper {
  min-width: 30px;
  width: 30px;
  max-width: 30px;
  min-height: 30px;
  height: 30px;
  max-height: 30px;
  overflow: hidden;
  border-radius: 50%;
  border: none;
  outline: none;
  background: transparent;
}


.header-profile__wrapper img {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.header-icon__button {
  border: none;
  outline: none;
  background: transparent;
}

.header-search input {
  height: 35px;
  border: 1px solid var(--bg-color-12);
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--primary-text-color);
  font-weight: 500;
  text-align: center;
  outline: none;
  min-width: 300px;
}

/* end of mainbar */

/* end of context */


.container_95 {
  max-width: 95% !important;
}


/* start of modal -1 */

.customModalOne .modal-dialog.modal-xl {
  /* max-width: 1000px; */
}

.customModalOne .modal-content {
  border-radius: 0px;
}

/* end of modal -1 */

/* start of accordion - 1 */

.accordion-one.accordion {
  background: transparent;
}

.accordion-one.accordion .card {
  border: none;
  background: transparent;
}

.accordion-one.accordion .card-header {
  background: transparent;
  border-bottom: unset;
  display: flex;
  align-items: center;
}

.accordion-one.accordion .card-header button {
  background: transparent;
  border: none;
  outline: none;
}

.accordion-one.accordion .card-header .accordion-one__action {
  background: var(--dark-color);
  min-width: 20px;
  width: 20px;
  max-width: 20px;
  min-height: 20px;
  height: 20px;
  max-height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-one.accordion .card-header .accordion-one__action svg {
  color: white;
  font-size: 13px;
}

.accordion-one.accordion .card-header:first-child {
  border-radius: 0px;
}

.accordion-one__title {
  font-family: var(--roboto-font);
  font-weight: 700;
  line-height: 35px;
  color: var(--dark-color);
}



.accordion-one__title-input {
  background: transparent;
  border: 1px solid var(--bg-color-13);
  outline: none;
  height: 35px;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  width: 100%;
  max-width: 250px;
}

.accordion-one__input--bg {
  background: var(--bg-color-1);
  border: none;
  outline: none;
  height: 35px;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  width: 100%;
  max-width: 250px;
}

.accordion-one__collapse--title {
  font-weight: 650;
  font-family: var(--open-sans-font);
  color: var(--dark-color);
}

.accordion-one__collapse--value {
  font-weight: 500;
  font-family: var(--open-sans-font);
}


/* end of accordion - 1 */



/* media */

@media (max-width:319px) {
  .select-one__single-value {
    font-size: 14px !important;
  }

  .select-one__option {
    font-size: 14px !important;
  }

  .select-one__placeholder {
    font-size: 14px !important;
  }

  .custom_modal .modal-content {
    max-width: 296px !important;
    margin-right: auto;
    margin-left: auto;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:first-child {
    font-size: 13px;
    display: flex;
    justify-content: center;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:nth-child(2) {
    font-size: 13px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link {
    font-size: 13px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link input {
    font-size: 13px;
  }

  .custom-select__two {
    min-width: 150px;
  }
}

@media (min-width:320px) and (max-width:575px) {
  .select-one__single-value {
    font-size: 14px !important;
  }

  .select-one__option {
    font-size: 14px !important;
  }

  .select-one__placeholder {
    font-size: 14px !important;
  }

  .custom_modal .modal-content {
    max-width: 296px !important;
    margin-right: auto;
    margin-left: auto;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:first-child {
    font-size: 13px;
    display: flex;
    justify-content: center;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:nth-child(2) {
    font-size: 13px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link {
    font-size: 13px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link input {
    font-size: 13px;
  }

  .custom-select__two {
    min-width: 150px;
  }

  /* start of accordion - 1 */

  .accordion-one__title {
    font-size: 16px;
  }

  .accordion-one__collapse--title {
    font-size: 14px;
  }

  .accordion-one__collapse--value {
    font-size: 14px;
  }

  /* end of accordion - 1 */
}

@media (min-width:576px) and (max-width:767px) {
  .select-one__single-value {
    font-size: 14px !important;
  }

  .select-one__option {
    font-size: 14px !important;
  }

  .select-one__placeholder {
    font-size: 14px !important;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:first-child {
    font-size: 13px;
    display: flex;
    justify-content: center;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:nth-child(2) {
    font-size: 13px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link {
    font-size: 13px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link input {
    font-size: 13px;
  }

  .custom-select__two {
    min-width: 150px;
  }

  /* start of accordion - 1 */

  .accordion-one__title {
    font-size: 16px;
  }

  .accordion-one__collapse--title {
    font-size: 14px;
  }

  .accordion-one__collapse--value {
    font-size: 14px;
  }

  /* end of accordion - 1 */
  .accordion-one__title-edit {
    font-size: 20px;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .select-one__single-value {
    font-size: 14px !important;
  }

  .select-one__option {
    font-size: 14px !important;
  }

  .select-one__placeholder {
    font-size: 14px !important;
  }

  .card_table .row.table-foot.asrt-table-foot {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:first-child {
    font-size: 13px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:nth-child(2) {
    font-size: 13px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link {
    font-size: 13px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link input {
    font-size: 13px;
  }

  .custom-select__two {
    min-width: 150px;
  }

  .customModalOne .modal-dialog.modal-xl {
    max-width: 720px !important;
  }

  /* start of accordion - 1 */

  .accordion-one__title {
    font-size: 18px;
  }

  .accordion-one__collapse--title {
    font-size: 15px;
  }

  .accordion-one__collapse--value {
    font-size: 15px;
  }

  /* end of accordion - 1 */

  .accordion-one__title-edit {
    font-size: 20px;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .select-one__single-value {
    font-size: 14px !important;
  }

  .select-one__option {
    font-size: 14px !important;
  }

  .select-one__placeholder {
    font-size: 14px !important;
  }

  .card_table .row.table-foot.asrt-table-foot {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:first-child {
    font-size: 13px;
    display: flex;
    justify-content: center;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:nth-child(2) {
    font-size: 13px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link {
    font-size: 13px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link input {
    font-size: 13px;
  }

  .custom-select__two {
    min-width: 200px;
  }

  .customModalOne .modal-dialog.modal-xl {
    max-width: 800px !important;
  }


  /* start of accordion - 1 */

  .accordion-one__title {
    font-size: 19px;
  }

  .accordion-one__collapse--title {
    font-size: 16px;
  }

  .accordion-one__collapse--value {
    font-size: 16px;
  }

  /* end of accordion - 1 */
  .accordion-one__title-edit {
    font-size: 20px;
  }
}

@media (min-width:1200px) and (max-width:1399px) {
  .select-one__single-value {
    font-size: 14px !important;
  }

  .select-one__option {
    font-size: 14px !important;
  }

  .select-one__placeholder {
    font-size: 14px !important;
  }

  .card_table .row.table-foot.asrt-table-foot {

    padding-top: 10px;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:first-child {
    font-size: 13px;
    display: flex;
    justify-content: center;
  }

  .card_table .row.table-foot.asrt-table-foot .col-md-6:nth-child(2) {
    font-size: 13px;
    display: flex;
    justify-content: center;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link {
    font-size: 13px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .card_table .row.table-foot.asrt-table-foot .pagination .page-link input {
    font-size: 13px;
  }

  .custom-select__two {
    min-width: 300px;
  }

  /* start of context */

  .dash_header {
    width: calc(100% - var(--sidebar_lg));
    margin-left: var(--sidebar_lg);
  }

  .width_less {
    width: calc(100% - var(--sidebar_lg));
    margin-left: var(--sidebar_lg);
    transition: 0.3s;
  }

  .width_full {
    width: calc(100% - var(--sidebar_xs));
    margin-left: var(--sidebar_xs);
    transition: 0.3s;
  }



  /* end of context */


  .customModalOne .modal-dialog.modal-xl {
    max-width: 900px;
  }

  /* start of accordion - 1 */

  .accordion-one__title {
    font-size: 19px;
  }

  .accordion-one__collapse--title {
    font-size: 16px;
  }

  .accordion-one__collapse--value {
    font-size: 16px;
  }

  /* end of accordion - 1 */
  .accordion-one__title-edit {
    font-size: 25px;
  }
}

@media (min-width:1400px) and (max-width:1799px) {
  .select-one__single-value {
    font-size: 15px !important;
  }

  .select-one__option {
    font-size: 15px !important;
  }

  .select-one__placeholder {
    font-size: 15px !important;
  }

  .custom-select__two {
    min-width: 300px;
  }

  .table-head__medium {
    font-size: 17px;
  }

  .table-body__regular {
    font-size: 16px;
  }

  /* start of context */

  .dash_header {
    width: calc(100% - var(--sidebar_lg));
    margin-left: var(--sidebar_lg);
  }

  .width_less {
    width: calc(100% - var(--sidebar_lg));
    margin-left: var(--sidebar_lg);
    transition: 0.3s;
  }

  .width_full {
    width: calc(100% - var(--sidebar_xs));
    margin-left: var(--sidebar_xs);
    transition: 0.3s;
  }


  /* end of context */

  .customModalOne .modal-dialog.modal-xl {
    max-width: 1000px;
  }

  /* start of accordion - 1 */

  .accordion-one__title {
    font-size: 20px;
  }

  .accordion-one__collapse--title {
    font-size: 17px;
  }

  .accordion-one__collapse--value {
    font-size: 17px;
  }

  /* end of accordion - 1 */
  .accordion-one__title-edit {
    font-size: 25px;
  }
}


@media (min-width:1800px) {
  .select-one__single-value {
    font-size: 17px !important;
  }

  .select-one__option {
    font-size: 17px !important;
  }

  .select-one__placeholder {
    font-size: 17px !important;
  }

  .custom-select__two {
    min-width: 400px;
  }

  .table-head__medium {
    font-size: 17px;
  }

  .table-body__regular {
    font-size: 16px;
  }

  /* start of context */

  .dash_header {
    width: calc(100% - var(--sidebar_lg));
    margin-left: var(--sidebar_lg);
  }

  .width_less {
    width: calc(100% - var(--sidebar_lg));
    margin-left: var(--sidebar_lg);
    transition: 0.3s;
  }

  .width_full {
    width: calc(100% - var(--sidebar_xs));
    margin-left: var(--sidebar_xs);
    transition: 0.3s;
  }



  /* end of context */

  .customModalOne .modal-dialog.modal-xl {
    max-width: 1200px;
  }

  /* start of accordion - 1 */

  .accordion-one__title {
    font-size: 20px;
  }

  .accordion-one__collapse--title {
    font-size: 17px;
  }

  .accordion-one__collapse--value {
    font-size: 17px;
  }

  /* end of accordion - 1 */
  .accordion-one__title-edit {
    font-size: 25px;
  }
}


/* tr {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
} */



/* media */

@media (max-width:319px) {}

@media (min-width:320px) and (max-width:575px) {}

@media (min-width:576px) and (max-width:767px) {}

@media (min-width:768px) and (max-width:991px) {}

@media (min-width:992px) and (max-width:1199px) {}

@media (min-width:1200px) and (max-width:1399px) {}

@media (min-width:1400px) and (max-width:1799px) {}

@media (min-width:1800px) {}


/* .task {}

.banner_section {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  background: gray;
}

.sidetab {
  position: fixed;
  background: red;
  left: 5%;
  top: 50%;
  transform: translateY(-50%);
}

.container {
  max-width: 95% !important;
}

.content_container {
  height: 100%;
  background: orange;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 2vw;
}

.sub_title {
  font-size: 1.1vw;
}

.sub_title {
  margin-top: 1vw;
} */